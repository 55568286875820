import Head from 'next/head'
import { signIn, useSession } from 'next-auth/react'
import React from 'react'
import AppLayout from 'components/ui/AppLayout'
import styled from 'styled-components'
import Context from 'wrappers/context'
import Link from 'next/link'

const StyledTitle = styled.h1`
  font-size: 48px;
`

const LabInstancesContainer = styled.div`
  display: grid;
  max-width: 800px;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid ${props => props.darkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'};
  padding-top: 40px;
  margin-top: 40px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const InstanceContainer = styled.div`
  display: block;
  border: 2px solid ${props => props.darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
  border-radius: 10px;
  padding: 30px 20px;

  h2 {
    margin: 0 0 25px;
  }

  span {
    display: block;
    margin-top: 10px;
    font-size: 12px;
  }
`

const InstanceLink = styled.div`
  margin-top: 20px;
  font-weight: 900;

  &:hover {
    opacity: 0.7;
  }
`

export default function Home() {

  const context = React.useContext(Context);

  // Expose the Session Context
  const { status: authStatus, data: session } = useSession();

  const activeInstancesView = (
    <div>
      <p>Here are your active lab instances:</p>
      <LabInstancesContainer
        darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
      >
        {context.userLabs && context.userLabs.map((instance) => {
          return (
            <InstanceContainer
              key={instance.get('deploy_id')}
              darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
            >
              <h2>{instance.get('project_name')}</h2>
              <span>Started on: <strong>{new Date(instance.get('created')).toUTCString()}</strong></span>
              <span>Expires on: <strong>{new Date(instance.get('expiration_date')).toUTCString()}</strong></span>
              <InstanceLink><Link href={`/l/${instance.get('project')}`}>Go to Lab →</Link></InstanceLink>
            </InstanceContainer>
          )
        })}
      </LabInstancesContainer>
    </div>
  );

  if (session?.user) {
    return (
      <AppLayout>
        <div>
          <StyledTitle>Hello, {session.user.fullName.split(' ')[0]}.</StyledTitle>
          <p>You are signed in with your email address <strong>{session.user.email}</strong>.</p>
          {context.userLabs && context.userLabs.size ? activeInstancesView : (
            <p>You don&apos;t currently have any active lab instances.</p>
          )}
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div>
        <StyledTitle>Welcome to Lab Launcher</StyledTitle>
        <p>Please sign into your <strong>{context.customer.getIn(['domain'])}</strong> account in order to access your labs.</p>
      </div>
    </AppLayout>
  )
}
